















import { DrawerDataView } from "@/models/class/DrawerDataView.class";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
export default Vue.extend({
  name: "AppDrawer",
  computed: {
    ...mapState({
      visible: (st: any) => st.drawerStore.visible,
      content: (st: any) => st.drawerStore.content,
      title: (st: any) => st.drawerStore.title,
      state: (st: any) => st.drawerStore.state,
      meta: (st: any) => st.drawerStore.meta,
    }),
    iconType(): string {
      let icon = "info-circle";

      if (this.state === "error") icon = "close-circle";
      else if (this.state === "success") icon = "check-circle";
      else if (this.state === "warning") icon = "warning";

      return icon;
    },
  },
  data() {
    return {
      copySucceed: false,
    };
  },
  methods: {
    ...mapMutations({
      toggle: "drawerStore/toggle",
    }),
    closeDrawer(): void {
      const dataView = new DrawerDataView({
        content: "",
        title: "",
        visible: false,
        metadata: null,
      });
      this.toggle(dataView);
    },
  },
});
