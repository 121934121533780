var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: { placement: "bottom", visible: _vm.visible },
      on: { close: _vm.closeDrawer }
    },
    [
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c(
            "a-space",
            [
              _c("a-icon", {
                staticStyle: { color: "#f5222d" },
                attrs: { theme: "filled", type: _vm.iconType }
              }),
              _vm._v(" " + _vm._s(_vm.title) + " ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-descriptions",
        [
          _c("a-descriptions-item", [
            _vm._v(" " + _vm._s(_vm.content || "-") + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }